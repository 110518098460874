<i18n>
{
  "en":{
    "title": "Overlays tool",
    "subtitle": "Select up to 20 sensors",
    "selection": {
      "sensorsSelected": "selected sensors",
      "viewDataBtn": "View data",
      "hint": "View data from all selected sensors."
    },
    "graphsTitle": "Angle alteration",
    "graphsSubtitle": "Trend of all angles detected by the selected sensors.",
    "thetaGraph": {
      "xTitle": "Time"
    },
    "phiGraph": {
      "xTitle": "Time"
    }
  },
  "it":{
    "title": "Tool di comparazione",
    "subtitle": "Seleziona fino a 20 sensori",
    "selection": {
      "sensorsSelected": "sensori selezionati",
      "hint": "Visualizza i dati dei sensori selezionati.",
      "viewDataBtn": "Visualizza dati"
    },
    "graphsTitle": "Variazione angolare",
    "graphsSubtitle": "Andamenti di tutti gli angoli rilevati dai sensori selezionati",
    "thetaGraph": {
      "xTitle": "Tempo"
    },
    "phiGraph": {
      "xTitle": "Tempo"
    }
  }
}  
</i18n>

<template>
  <move-card class="mx16 m-container" rounded>
    <!-- Loading component -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <MoveSpinner2 v-if="getLoading" :soft="true"></MoveSpinner2>

    <!-- Selection component -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <move-card rounded class="selection-card mb64">
      <div class="selection-container scroll2">
        <move-text h3 b class="pb16"> {{ $t("title") }} </move-text>
        <move-text p secondary class="pb4"> {{ $t("subtitle") }}: </move-text>
        <move-select-multiple
          multiple
          :options="getTiltMetersAvailable"
          :getOptionLabel="getSensorLabel"
          v-model="titlmetersSelected"
          class="selection"
          :noDrop="titlmetersSelected.length >= getMaxSensorsSelected"
          :dropdown-should-open="() => {return true}"
        ></move-select-multiple>
        <move-text p secondary>
          {{
            `${titlmetersSelected.length}/20 ${$t(
              "selection.sensorsSelected"
            )}`
          }}
        </move-text>
      </div>
      <div class="actions-container">
        <move-text p secondary class="pb16"> {{ $t("selection.hint") }} </move-text>
        <move-btn
          :disabled="!titlmetersSelected.length"
          outlined
          primary
          rounded
          large
          @click="onViewData"
        >
          <move-text h3 b class="px4">{{ $t("selection.viewDataBtn") }}</move-text>
        </move-btn>
      </div>
    </move-card>

    <!-- Graphs description -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="title d-flex align-items-center">
      <move-text h3 b class="mr8"> {{ $t("graphsTitle") }} </move-text>
      <InfoContainer />
    </div>
    <move-text p secondary class="pb8"> {{ $t("graphsSubtitle") }} </move-text>

    <!-- Phi Graph -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <move-graph
      :traces="getPhiGraph.traces"
      :yTitle="getPhiGraph.yTitle"
      :xTitle="getPhiGraph.xTitle"
      :colorway="getColors"
      hovermode="x unified"
      :showLegend="true"

    ></move-graph>

    <!-- Theta Graph -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <move-graph
      :traces="getThetaGraph.traces"
      :yTitle="getThetaGraph.yTitle"
      :xTitle="getThetaGraph.xTitle"
      :colorway="getColors"
      hovermode="x unified"
      :showLegend="true"
    ></move-graph>
  </move-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getSensorFromEui } from "../../../helpers/sensors";
import MoveSpinner2 from "../../ui_V2/MoveSpinner2.vue";
import InfoContainer from "./InfoContainer.vue";
import _ from "lodash";

const DEGREES = "degrees";
export default {
  name: "overlays",
  components: { MoveSpinner2, InfoContainer },
  data() {
    return {
      titlmetersSelected: [],
      isInfoVisible: false,
    };
  },
  computed: {
    /****************************************************************/
    /* VUEX GETTERS */
    /****************************************************************/
    ...mapGetters({
      getTiltmeters: "overlays/getTiltmeters",
      getDateRange: "overlays/getDateRange",
      getTiltData: "overlays/getTiltData",
      getLoading: "overlays/getLoading",
      getTiltsUnit: "overlays/getTiltsUnit",
      getMaxSensorsSelected: "overlays/getMaxSensorsSelected",
    }),
    getTitlsUnitText() {
      return this.getTiltsUnit === DEGREES ? "°" : "[mm/m]";
    },
    getTitltsEui() {
      return this.titlmetersSelected.map((s) => s.eui);
    },
    getTiltMetersAvailable() {
      return this.getTiltmeters.filter((s) => !this.getTitltsEui.includes(s.eui));
    },

    /* PHI GRAPH */
    /****************************************************************/
    getPhiGraph() {
      return {
        traces: this.getTiltData.phiAngle.traces.map((trace) => {
          return {
            x: trace.x,
            y: trace.y,
            type: "scatter",
            hovertemplate: `<b>%{y:.0}</b> ${this.getTitlsUnitText}`,
            name:this.getSensorLabel(getSensorFromEui(this.getTiltmeters, trace.eui)),
          };
        }),
        yTitle: `Δφ Tilt ${this.getTitlsUnitText}`,
        xTitle: `${this.$t("phiGraph.xTitle")} (Hr)`,
      };
    },

    /* THETA GRAPH */
    /****************************************************************/
    getThetaGraph() {
      return {
        traces: this.getTiltData.thetaAngle.traces.map((trace) => {
          return {
            x: trace.x,
            y: trace.y,
            type: "scatter",
            hovertemplate: `<b>%{y:.0}</b> ${this.getTitlsUnitText}`,
            name: this.getSensorLabel(getSensorFromEui(this.getTiltmeters, trace.eui)),
          };
        }),
        yTitle: `Δϑ Tilt ${this.getTitlsUnitText}`,
        xTitle: `${this.$t("thetaGraph.xTitle")} (Hr)`,
      };
    },
    getColors() {
      return this.getTiltData.phiAngle.traces.map((trace) => trace.color);
    },
  },
  methods: {
    /****************************************************************/
    /* VUEX ACTIONS */
    /****************************************************************/
    ...mapActions({
      fetchTiltData: "overlays/fetchTiltData",
      setLoading: "overlays/setLoading",
    }),

    /****************************************************************/
    /* VUEX WRAPPERS ACTIONS */
    /****************************************************************/
    async fetchTiltDataWrapper() {
      this.setLoading(true);
      await this.fetchTiltData({
        tiltmeters: this.getTitltsEui,
        dateRange: this.getDateRange,
      });
      this.setLoading(false);
    },

    parseLocalStorageData() {
      const euis = JSON.parse(localStorage.getItem("overlaysEuis")) || [];
      this.titlmetersSelected = euis.filter(eui => {
        const euis = this.getTiltmeters.map(s => s.eui)
        return euis.includes(eui)
      })
      .map((eui) => getSensorFromEui(this.getTiltmeters, eui))
    },

    getSensorLabel(sensor) {
      return `${sensor.shortEui} - ${sensor.userConfig.name}`;
    },

    //listen for eui and name changes
    sensorsAreChanged(oldSensors, newSensors) {
      const oldParams = oldSensors.map((s) => {
        return {
          eui: s.eui,
          name: s.userConfig.name,
        }
      });
      const newParams = newSensors.map((s) =>{
        return {
          eui: s.eui,
          name: s.userConfig.name,
        }
      });
      return !_.isEqual(oldParams, newParams);
    },

    async onViewData() {
      localStorage.removeItem("overlaysEuis");
      localStorage.setItem("overlaysEuis", JSON.stringify(this.getTitltsEui));
      await this.fetchTiltDataWrapper();
    },
  },
  watch: {
    getTiltsUnit: {
      handler: async function () {
        await this.fetchTiltDataWrapper();
      },
      deep: true,
    },
    getDateRange: {
      handler: async function () {
        await this.fetchTiltDataWrapper();
      },
      deep: true,
    },
    getTiltmeters: {
      handler: async function (oldSensors, newSensors) {
        if (this.sensorsAreChanged(oldSensors, newSensors)) {
          this.parseLocalStorageData();
          await this.fetchTiltDataWrapper();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.parseLocalStorageData();
    await this.fetchTiltDataWrapper();
  },
};
</script>
<style scoped>
.m-container {
  text-align: left;
  overflow-x: auto;
}

.selection-card {
  text-align: left;
  background-color: var(--secondary-color-light);
  padding: 24px;
  display: grid;
  grid-template-columns: minmax(200px, 3fr) minmax(200px, 2fr);
  min-width: 600px;
}

.selection-card .selection-container {
  padding-right: 64px;
  display: flex;
  flex-direction: column;
}

.selection-card .actions-container {
  border-left: 2px solid var(--secondary-color-light-3);
  padding-left: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>
