<i18n>
  {
    "en": {
        "title": "Phi and Theta angles",
        "subTitle": "The sample rate is relative to the set acquisition mode.The calculation of these angles is based on projections, averaged over an acquisition interval of 1 minute, of the gravity vector on the three axes of the sensor.",
        "li1": "φ - ANGLE PHI: is the angle between the Z axis and its projection on the horizontal plane.",
        "li2": "θ - THETA ANGLE: is the angle between the Y axis and its projection on the horizontal plane."
  
    },
    "it": {
        "title": "Angoli Phi e Theta",
        "subTitle": "La frequenza di campionamento è relativa alla modalità di acquisizione impostata. Il calcolo di questi angoli è basato sulle proiezioni, mediate su un intervallo di acquisizione pari ad 1 minuto, del vettore gravità sui tre assi del sensore.",
        "li1": "φ - ANGOLO PHI: Rappresenta l’angolo compreso fra l’asse Z e la sua proiezione sul piano orizzontale.",
        "li2": "θ - ANGOLO THETA: Rappresenta l’angolo compreso fra l’asse Y e la sua proiezione sul piano orizzontale."
    }
  }
  </i18n>

<template>
  <move-ballon
    arrow="left"
    width="550px"
  >
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
      </ul>
    </template>
    <template v-slot:side>
      
      <ModalVideoTutorial :video="'tiltmeter'" />
    </template>
  </move-ballon>
</template>
<script>
import ModalVideoTutorial from "../../ui/ModalVideoTutorial.vue"
export default {
  name: "InfoContainer",
  components: {
    ModalVideoTutorial
  },
};
</script>
<style scoped>
li {
  margin: 6px 0;
}
</style>
