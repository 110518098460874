<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:nav>
        <DateRangeSelect
          :initDateRange="dateRangeTemp"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
          @structure-selected="selectStructure"
          @date-selected="updateDateRange"
          @range-selected="onRangeSelected"
          @dayclick="onDayClick"
          :minDate="minDate"
          :maxDate="maxDate"
        />
      </template>
      <template v-slot:content>
				<Overlay />
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import Overlay from "../../components/modules/overlays/Overlays.vue"

export default {
  name: "railsview",
  components: {
    PageTemplate,
    DateRangeSelect,
    Overlay
  },
  data(){
    return {
      minDate: null,
      maxDate: new Date(),
      dateRange:  [new Date(), new Date()],
      dateRangeTemp:  [new Date(), new Date()],
    }
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getDateRange: "overlays/getDateRange",
      getMaxDateRange: "overlays/getMaxDateRange"
    }),
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      setDateRange: "overlays/setDateRange",
    }),
    updateDateRange() {
      //Update the range only when click on 'update' button
      this.setDateRange(this.dateRangeTemp);
    },
    onRangeSelected(dateRange){
      //when the selection is completed reset minDate/maxDate
      this.minDate = null;
      this.maxDate = new Date();
      // console.log('dateRange', dateRange)
      this.dateRangeTemp = [new Date(dateRange[0].toDate()), new Date(dateRange[1].toDate())]
      
    },
    onDayClick(v){
      //caluclate dinamically the range
      this.minDate = new Date(new Date(v.date).getTime() - this.getMaxDateRange)
      this.maxDate = new Date(Math.min(new Date(new Date(v.date).getTime() + this.getMaxDateRange), new Date()))
      //store every time the range
      try {
        this.dateRangeTemp = [v.attributes[0].dates[0].start, v.attributes[0].dates[0].end]
      } catch (e) {
        this.dateRangeTemp = [new Date(), new Date()]
      }
      
      console.log('day click', v)
    }
  },

  async mounted() {
    this.dateRangeTemp = this.getDateRange;
    await this.fetchStructures();
    if (!this.getSelectedStructure) {
      await this.selectStructure(this.getStructures[0]);
    }
  },
};
</script>
<style scoped>
.m-content {
  padding: 0px 16px;
}
</style>
